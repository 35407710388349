<!-- Props to add:
  1. selectedDiffGrp - For selected difficulty group
  2. Difficulty - For difficulty group
  3. QuestionDiagramURL - to display Question
  4. isQustionSelected - to display checked on selected question -->

<template>
  <div>
    <div class="ques-box flex" :class="{ disable: SelectedQns }">
      <div
        class="ques-checkbox relative"
        :class="setDiffClass(selectedDiffGrp)"
        :style="showCheckbox ? 'display: block' : 'display: none'"
        style="max-width: 39px; height: 39px"
      ></div>
      <div
        class="ques-img relative pointer flex flex-column"
        :class="[Difficulty, { 'latext-img': isLatex === 1 }]"
        :style="showReplaceBtn ? 'left: 7px;' : ''"
      >
        <img
          :src="`${getAzureUrl(QuestionDiagramURL)}`"
          alt=""
          class="h-100 w-100"
          style="border-radius: 5px"
          v-if="isLatex === 0"
          draggable="false"
        />
        <vue-mathjax
          :formula="LatexUrl"
          :options="VueMathJaxOptions"
          :safe="false"
          v-if="isLatex === 1"
          class="space-break fs-18 fw-500"
        />
        <div v-if="diagramUrl !== null">
          <img
            class="col-sm-12 col-md-9"
            :src="`${getAzureUrl(diagramUrl)}`"
            alt=""
            :style="{
              width: `${diagramPosition[2] * 100}%`,
              height: `${diagramPosition[3] * 100}%`,
            }"
            draggable="false"
          />
        </div>
        <div
          v-if="
            isLatex === 1 && AnswerOption_A && AnswerOption_B && AnswerOption_C && AnswerOption_D
          "
        >
          <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
            <span v-if="!AnswerOption_A.includes('(A)')">(A)</span>
            <vue-mathjax :formula="AnswerOption_A" :options="VueMathJaxOptions" :safe="false" />
          </h1>
          <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
            <span v-if="!AnswerOption_B.includes('(B)')">(B)</span>
            <vue-mathjax :formula="AnswerOption_B" :options="VueMathJaxOptions" :safe="false" />
          </h1>
          <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
            <span v-if="!AnswerOption_C.includes('(C)')">(C)</span>
            <vue-mathjax :formula="AnswerOption_C" :options="VueMathJaxOptions" :safe="false" />
          </h1>
          <h1 class="fs-18 fw-600 dark-text m-0 mt-5 space-break">
            <span v-if="!AnswerOption_D.includes('(D)')">(D)</span>
            <vue-mathjax :formula="AnswerOption_D" :options="VueMathJaxOptions" :safe="false" />
          </h1>
        </div>
        <h3
          class="fs-14 fw-600 pointer absolute green-btn"
          v-if="showReplaceBtn"
          @click="replaceQns(qnsData)"
        >
          Replace
        </h3>
        <h3
          class="fs-14 fw-600 pointer absolute green-btn"
          style="opacity: 1; bottom: -10px"
          v-if="SelectedQns"
        >
          Selected
        </h3>
        <h3
          class="fs-14 fw-600 pointer absolute green-btn"
          :style="showAddedText ? 'opacity : 1; background: #FF7675;' : ''"
          v-if="showAddBtn"
        >
          {{ showAddedText ? "Remove" : "Add" }}
        </h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      VueMathJaxOptions: {
        extensions: ["tex2jax.js"],
        showProcessingMessages: false,
        jax: ["input/TeX", "output/chtml"],
        lazyAlwaysTypeset: null,
        showMathMenu: false,
        tex2jax: {
          inlineMath: [
            ["$", "$"],
            ["\\(", "\\)"],
          ],
          displayMath: [
            ["$$", "$$"],
            ["\\[", "\\]"],
          ],
          processEscapes: false,
          processClass: "tex2jax_process",
        },
      },
      QuestionDiagramURL: "",
      AnswerOption_A: null,
      AnswerOption_B: null,
      AnswerOption_C: null,
      AnswerOption_D: null,
      isLatex: 0,
      LatexUrl: "",
      diagramUrl: null,
      diagramPosition: [],
    };
  },
  props: {
    Difficulty: String,
    selectedDiffGrp: String,
    QuestionDetail: Object,
    isQustionSelected: Boolean,
    showReplaceBtn: {
      type: Boolean,
      default: false,
    },
    SelectedQns: {
      type: Boolean,
      default: false,
    },
    showAddedText: {
      type: Boolean,
      default: false,
    },
    showAddBtn: {
      type: Boolean,
      default: false,
    },
    showCheckbox: {
      type: Boolean,
      default: true,
    },
    qnsData: Object,
  },
  mounted() {
    if (this.QuestionDetail.Q_LATEX_Status === "Y" && this.QuestionDetail.Q_LATEX) {
      this.LatexUrl = this.QuestionDetail.Q_LATEX;
      this.AnswerOption_A = this.QuestionDetail.AnswerOption_A;
      this.AnswerOption_B = this.QuestionDetail.AnswerOption_B;
      this.AnswerOption_C = this.QuestionDetail.AnswerOption_C;
      this.AnswerOption_D = this.QuestionDetail.AnswerOption_D;
      if (this.QuestionDetail?.DiagramUrl) {
        const qnsDiagramDetail = JSON.parse(this.QuestionDetail?.DiagramUrl);
        this.diagramUrl = qnsDiagramDetail?.diagram_0?.url;
        this.diagramPosition = qnsDiagramDetail?.diagram_0?.position;
      }
      this.isLatex = 1;
    } else {
      this.QuestionDiagramURL = this.QuestionDetail.QuestionDiagramURL;
      this.isLatex = 0;
    }
  },
  methods: {
    setDiffClass(diffClass) {
      if (diffClass === "basic" && this.isQustionSelected) {
        return "basic";
      }
      if (diffClass === "easy" && this.isQustionSelected) {
        return "easy";
      }
      if (diffClass === "normal" && this.isQustionSelected) {
        return "normal";
      }
      if (diffClass === "hard" && this.isQustionSelected) {
        return "hard";
      }
      if (diffClass === "tough" && this.isQustionSelected) {
        return "tough";
      }
      return "";
    },
    replaceQns(qnsData) {
      this.$emit("replaceBtnTriggered", qnsData);
    },
    getAzureUrl(imageUrl) {
      if (imageUrl.startsWith("~")) {
        imageUrl = imageUrl.replace("~", process.env.VUE_APP_STORAGE_URL);
      }
      return imageUrl;
    },
  },
};
</script>

<style scoped>
.skyblue-text {
  color: #3751ff;
}
.dark-tex {
  color: #333333;
}
.ques-box {
  /* max-width: 700px; */
  margin-bottom: 30px;
}
.ques-box.disable {
  pointer-events: none;
  cursor: none;
}
.ques-checkbox {
  max-width: 35px;
  width: 100%;
  height: 35px;
  background: white;
  border-radius: 50%;
  margin-right: 13px;
  border: 1px solid #e0e4f0;
  cursor: pointer;
}
.ques-img {
  border: 1px solid #e0e4f0;
  border-radius: 5px;
  max-width: 800px;
  min-width: 300px;
  width: 100%;
}
.ques-img.latext-img {
  background: white;
  padding: 5px 10px 10px 10px;
}
.ques-checkbox.basic {
  background: #16a085;
}
.ques-img.basic::before,
.ques-img.latext-img.basic:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #16a085;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}
.ques-img.latext-img.basic:before {
  left: -2px !important;
}
.ques-checkbox.easy {
  background: #8bc32e;
}
.ques-img.easy::before,
.ques-img.latext-img.easy:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #8bc32e;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}
.ques-img.latext-img.easy:before {
  left: -2px !important;
}

.ques-checkbox.hard {
  background: #ff835ce5;
}
.ques-checkbox::before {
  position: absolute;
  left: 6px;
  top: 42%;
  height: 14px;
  width: 5px;
  border-radius: 5px;
  background-color: #fff;
  content: "";
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}
.ques-checkbox::after {
  position: absolute;
  left: 1px;
  bottom: 8px;
  height: 5px;
  width: 21px;
  border-radius: 4px;
  background-color: #fff;
  content: "";
  transform: translateX(15px) rotate(-45deg);
  transform-origin: left bottom;
}

.ques-img.hard::before,
.ques-img.latext-img.hard:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #ff835ce5;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}
.ques-img.latext-img.hard:before {
  left: -2px !important;
}
.ques-checkbox.normal {
  background: #ffbf44;
}
.ques-img.normal::before,
.ques-img.latext-img.normal:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #ffbf44;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}
.ques-img.latext-img.normal:before {
  left: -2px !important;
}
.ques-checkbox.tough {
  background: #b03e3e;
}
.ques-img.tough::before,
.ques-img.latext-img.tough:before {
  position: absolute;
  content: "";
  width: 5px;
  height: 100%;
  background: #b03e3e;
  border-radius: 5px 0px 0px 5px;
  left: 0;
  top: 0;
}
.ques-img.latext-img.tough:before {
  left: -2px !important;
}
.green-btn {
  bottom: 0;
  right: 5px;
  width: 100px;
  height: 28px;
  background: #37841c;
  border-radius: 5px;
  color: white;
  text-align: center;
  line-height: 28px;
  opacity: 0;
  transition: all 0.3s ease-out;
}
.ques-img:hover .green-btn {
  opacity: 1;
}
.option {
  max-width: 450px;
  margin: 20px 0;
}
</style>
