<template>
<div>
    <div v-if="this.entityDataValidation">
        <div class="filterComponent" style="padding-left: 15px">
            <p class="fs-13 fw-700 flex flex-between m-0 pointer" style="font-family: open sans" @click="isEntityFilterShow = !isEntityFilterShow">
                {{ this.entityDataObject.Name
          }}<i class="material-icons materialIcon pointer name-dropdown">{{ isEntityFilterShow ? "expand_less" : "expand_more" }}</i>
            </p>
            <div class="entityNameList" v-if="isEntityFilterShow" :style="!showAll ||  entityDataObject.entityArray.length === 1 ? 'margin-top: 10px;' : ''">
                <div class="flex flex-between items-center" v-if="entityDataObject.entityArray.length > 1 && showAll">
                    <div class="dark-text pointer entityTitle" style="font-size: 13px; font-weight: 600" :class="{
                  active:
                  this.entityDataObject.entityArray.length ===
                  this.selectedEntityId.length,
                }" @click="getEntityId(-1)">
                        <p>ALL</p>
                    </div>
                    <div style="margin-left: 25px" v-if="selectedEntityId.length === entityDataObject.entityArray.length">
                        <i class="material-icons materialIcon pointer" style="font-size: 10px; color: #a1a1a1" @click="deselectAll()">close</i>
                    </div>
                </div>
                <div class="flex items-baseline flex-between" v-for="(entityData, index) in entityDataObject.entityArray" :key="index">
                    <div class="pointer dark-text entityTitle relative" style="font-size: 13px; font-weight: 600; margin-top: -10px" :class="{
                active:
                  selectedEntityId.includes(entityData.entityId) ||
                  entityDataObject.entityArray.length === 1,
              }">
                        <p @click="getEntityId(entityData.entityId)" style="margin: 15px 0" :title="entityData.entityName">
                            {{
                  entityData.entityName.length > 25
                    ? entityData.entityName.slice(0, 25) + "..."
                    : entityData.entityName
                }}
                        </p>
                    </div>
                    <div v-if="
                selectedEntityId.includes(entityData.entityId) && entityDataObject.entityArray.length > 1  && !this.isSingleSelect">
                        <i class="material-icons materialIcon pointer" style="font-size: 10px; color: #a1a1a1" @click="removeEntityId(entityData.entityId)">close</i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      isEntityFilterShow: true,
      entityListIndex: null,
      selectedEntityId: [],
      entityDataValidation: false,
    };
  },
  props: {
    entityDataObject: Object,
    showAll: {
      type: Boolean,
      default: true,
    },
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
    selectedId: Number,
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.entityDataObject.entityArray.length > 0) {
        if (this.isSingleSelect) {
          this.selectedEntityId.splice(0, 1);
          this.selectedEntityId.push(this.selectedId);
          this.$emit("selectedEntityId", this.selectedId);
        } else {
          this.entityDataObject.entityArray.forEach((item) => {
            this.selectedEntityId.push(item.entityId);
          });
          this.$emit("selectedEntityId", this.selectedEntityId);
        }
      }
      if ("Name" in this.entityDataObject && "entityArray" in this.entityDataObject) {
        this.entityDataValidation = true;
      }
    },
    getEntityId(data) {
      this.entityListIndex = data;
      if (this.selectedEntityId.includes(data) && this.entityDataObject.entityArray.length > 1 && !this.isSingleSelect) {
        this.removeEntityId(data);
      } else if (this.entityDataObject.entityArray.length !== this.selectedEntityId.length && !this.selectedEntityId.includes(data)) {
        if (this.isSingleSelect) {
          this.selectedEntityId.splice(0, 1);
          this.selectedEntityId.push(data);
          this.$emit("selectedEntityId", data);
        } else if (this.entityListIndex === -1 && this.showAll) {
          this.selectedEntityId = [];
          this.entityDataObject.entityArray.forEach((item) => {
            this.selectedEntityId.push(item.entityId);
          });
          this.$emit("selectedEntityId", this.selectedEntityId);
        } else if (!this.selectedEntityId.includes(data) && !this.isSingleSelect) {
          this.selectedEntityId.push(data);
          this.$emit("selectedEntityId", this.selectedEntityId);
        }
      }
    },
    removeEntityId(ClusterId) {
      if (this.selectedEntityId.length > 1) {
        const index = this.selectedEntityId.indexOf(ClusterId);
        if (index > -1) {
          this.selectedEntityId.splice(index, 1);
        }
        this.$emit("selectedEntityId", this.selectedEntityId);
        if (this.selectedEntityId.length === 0 && !this.isSingleSelect) {
          this.entityDataObject.entityArray.forEach((item) => {
            this.selectedEntityId.push(item.entityId);
          });
          this.$emit("selectedEntityId", this.selectedEntityId);
        }
      } else {
        this.deselectAll();
      }
    },
    deselectAll() {
      this.selectedEntityId = [];
      this.$emit("selectedEntityId", this.selectedEntityId);
    },
  },
};
</script>

<style scoped>
input {
    /* position: relative !important; */
    opacity: 1 !important;
    pointer-events: visible !important;
}

.dark-text {
    color: #333333;
}

.materialIcon {
    font-size: 15px;
    padding-right: 10px;
    padding-top: 4px;
}

.entityTitle {
    color: #8e8e8e !important;
}

.entityTitle.active {
    color: #3751ff !important;
}

.entityNameList {
    overflow-y: auto;
    max-height: 200px;
}

::-webkit-scrollbar {
    display: none;
}

.name-dropdown {
    padding-right: 7px;
}

.filterComponent {
    padding: 16px 15px;
    padding-right: 0;
}
</style>
