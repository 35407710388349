<template>
<div v-if="this.selectedClusterIdQuestionslist" class="difficultlevel" style="border-top: 1px solid #E0E4F0;">
    <p class="flex flex-between diffLevelTitle pointer" @click="isDiffLevelFilterShow = !isDiffLevelFilterShow" style="font-family: open sans">Difficulty<i class="material-icons materialIcon pointer">{{ isDiffLevelFilterShow ? "expand_less" : "expand_more"}}</i></p>
    <div class="diffGrpButtons" v-if="isDiffLevelFilterShow">
        <div class="flex">
                 <div class="dark-text pointer difficultylevelTitle all-btn" :class="[{active: selectedDiffGrpData === enableDifficultyCount &&  selectedDiffGrpData.length > 0 || diffLevelIndex === 'all' && selectedDiffGrpData.length > 0}, {'disabled' : disableAllBtn()}]" @click="selectDiffLevel('all')">ALL</div>
        </div>
        <div style="display: flex; flex-wrap: wrap; gap: 5px;">
            <button class="difficultyLevelButton" v-for="(diffData, index) in questionListArray" :key="index" @click="selectDiffLevel(diffData.DifficultyGroup)" :disabled="diffData.DifficultyGroupId === 0" :style="{'background-color': selectedDiffGrpData.includes(diffData.DifficultyGroup) ? diffData.color : '', color: selectedDiffGrpData.includes(diffData.DifficultyGroup) ? '#ffff' : ''}"><span>{{diffData.DifficultyGroup}}</span></button>
        </div>
    </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      diffLevelIndex: "all",
      selectedAll: [],
      isDiffLevelFilterShow: true,
      selectedDiffGrpData: [],
      diffiGrpData: [{
        DifficultyGroup: "Basic",
        DifficultyGroupId: 0,
        color: "#16A085",
        isSelected: false,
      }, {
        DifficultyGroup: "Easy",
        DifficultyGroupId: 0,
        color: "#8BC32E",
        isSelected: false,
      }, {
        DifficultyGroup: "Normal",
        DifficultyGroupId: 0,
        color: "#FFBF44",
        isSelected: false,
      }, {
        DifficultyGroup: "Hard",
        DifficultyGroupId: 0,
        color: "#FF835CE5",
        isSelected: false,
      },
      {
        DifficultyGroup: "Tough",
        DifficultyGroupId: 0,
        color: "#B03E3E",
        isSelected: false,
      },
      ],
      enableDifficultyCount: null,
    };
  },

  props: {
    selectedClusterIdQuestionslist: Array,
  },
  computed: {
    questionListArray() {
      if (this.selectedClusterIdQuestionslist.length > 0) {
        const data = Array.from(new Set(this.selectedClusterIdQuestionslist.map((item) => item.DifficultyGroup)));
        this.selectedClusterIdQuestionslistList();
        data.forEach((obj) => {
          this.diffiGrpData.forEach((item) => {
            if (item.DifficultyGroup === obj) {
              // eslint-disable-next-line no-param-reassign
              item.DifficultyGroupId = 1;
            }
          });
          this.selectedDiffGrpData = [];
          data.forEach((items) => {
            this.selectedDiffGrpData.push(items);
          });
        });
      }
      return this.diffiGrpData;
    },
  },
  methods: {
    selectedClusterIdQuestionslistList() {
      for (let i = 0; i < this.diffiGrpData.length; i += 1) {
        this.diffiGrpData[i].DifficultyGroupId = 0;
        this.diffiGrpData[i].isSelected = false;
        this.selectedDiffGrpData = [];
      }
    },
    selectDiffLevel(data) {
      this.enableDifficultyCount = this.questionListArray.filter((item) => item.DifficultyGroupId === 1);
      this.diffLevelIndex = data;
      if (this.diffLevelIndex !== "all") {
        if (this.selectedDiffGrpData.includes(data)) {
          const index1 = this.selectedDiffGrpData.indexOf(data);
          this.selectedDiffGrpData.splice(index1, 1);
          this.$emit("selectedDiffLevel", this.selectedDiffGrpData);
        } else {
          this.selectedDiffGrpData.push(data);
          if (this.selectedDiffGrpData.length === this.enableDifficultyCount.length) {
            this.diffLevelIndex = "all";
          }
          this.$emit("selectedDiffLevel", this.selectedDiffGrpData);
        }
      }
      if (this.diffLevelIndex === "all") {
        this.selectedAll = Array.from(new Set(this.selectedClusterIdQuestionslist.map((item) => item.DifficultyGroup)));
        this.selectedAll.forEach((items) => {
          if (!this.selectedDiffGrpData.includes(items)) {
            this.selectedDiffGrpData.push(items);
          }
        });
        this.$emit("selectedDiffLevel", this.selectedDiffGrpData);
      }
    },
    disableAllBtn() {
      let disableBtn = true;
      for (let i = 0; i < this.questionListArray.length; i += 1) {
        if (this.questionListArray[i].DifficultyGroupId === 1) {
          disableBtn = false;
          break;
        }
      }
      return disableBtn;
    },
  },

};
</script>

<style scoped>
input {
    /* position: relative !important; */
    opacity: 1 !important;
    pointer-events: visible !important;
}

.dark-text {
    color: #333333
}

.diffLevelTitle {
    font-size: 13px;
    font-weight: 700;
    color: #333333;
    /* padding-left: 14px; */
    margin: 16px 0;
}

.materialIcon {
    font-size: 15px;
    padding-right: 10px;
    padding-top: 4px;
}

.difficultylevelTitle {
    inline-size: 158px;
    overflow-wrap: break-word;
}

/* .difficultylevelTitle.active {
  background: #17af7e;
    color: #fff !important;
} */

.difficultyLevelButton {
    height: 24px;
    background: #FFFFFF;
    border: 1px solid #E0E4F0;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    /* line-height: 16px; */
    color: #8E8E8E;
    cursor: pointer;
    max-width: 62px;
    min-width: 49px;
}

.difficultyLevelButton:disabled {
    background-color: #F1F1F1;
    pointer-events: none;
}
.all-btn{
    color: #8E8E8E;
    font-weight: 600;
    text-align: center;
    line-height: 22px;
    width: max-content;
    margin-bottom: 16px;
    text-align: left;
    font-size: 13px;
    margin-left: 1px;
}
/* .all-btn.disabled{
  background-color: #F1F1F1;
    pointer-events: none;
  color: #8E8E8E;

} */
.all-btn.active {
    /* background: #5161cd;
    color: #fff !important; */
    color: #5161cd !important;
}
.diffGrpButtons{
  padding-bottom: 15px;
  width: 215px;
}
.difficultlevel{
  padding-left: 15px;
}
</style>
